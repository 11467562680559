<template>
  <div>
    <el-dialog
        :title="'款式 '+styleInfo.typeName+'-'+styleInfo.styleName+' 价格'"
        width="65%"
        :visible.sync="dialogState"
        :modal-append-to-body="false"
        @close="close"
        v-loading="loading"
        center>
      <el-row :gutter="20">
        <el-col :span="12" v-for="(item,key) in stylePriceArr" :key="key">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>{{ item.shopName }}</span>
              &nbsp;&nbsp;&nbsp;
              <el-button icon="el-icon-refresh" type="text" @click="syncPrice(item)">同步价格</el-button>
              <el-button style="float:right;" :icon="readFlag[key]?'el-icon-edit':'el-icon-finished'" type="text" @click="changeReadOnly(key)">{{readFlag[key]?"编辑":"关闭"}}</el-button>
            </div>
            <div v-for="children in item.data" :key="children.index" class="text item">
              <el-input v-model="priceModel[children.index]" :disabled="readFlag[key]" type="number" @change="inputChange(children,$event,key) ">
                <template slot="prepend">{{ children.priceType }}</template>
                <template slot="append">元</template>
              </el-input>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="editState=true">添加价格</el-button>
      </span>
    </el-dialog>
    <el-drawer
        title="添加价格"
        :visible.sync="editState"
        :destroy-on-close ="true"
        :append-to-body="true">
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form label-width="100px" :model="form" :rules="rules" ref="form">
            <el-form-item label="店铺" prop="shopId">
              <el-select v-model="form.shopId" placeholder="店铺" clearable>
                <el-option
                    v-for="item in shopArr"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="价格类型" prop="priceType">
              <el-select v-model="form.priceType" placeholder="价格类型" clearable>
                <el-option
                    v-for="item in priceTypeArr"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="价格" prop="price">
              <el-input v-model="form.price" type="number" style="width: 220px"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="addPriceCheck">添加价格</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-drawer>
  </div>

</template>

<script>

export default {
  name: "style-price",
  data() {
    return {
      form:{
        shopId:"",
        priceType:"",
        price:"",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },

      //价格
      priceModel: [],
      //控制该店铺是否可以编辑
      readFlag:[],
      //格式化后的款式价格  格式看打印
      stylePriceArr:[],

      shopArr: [],
      priceTypeArr: [],

      rules: {
        shopId: [
          {required: true, message: '请选择店铺', trigger: 'change'},
        ],
        priceType: [
          {required: true, message: '请选择价格类型', trigger: 'change'},
        ],
        price: [
          {required: true, message: '请填写价格', trigger: 'change'},
        ],
      },
      loading:false,
      editState: false,
      dialogState: this.state,
    }
  },
  created() {
    this.queryShop();
    this.queryPriceType();
    this.queryPriceByStyleId();
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  methods: {
    //查询店铺
    queryShop() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopArr = JSON.parse(response.data.data)
      })
    },
    //查询价格类型
    queryPriceType() {
      this.$selectUtils.queryStylePriceType().then(response => {
        this.priceTypeArr = JSON.parse(response.data.data)
      })
    },
    //根据ID 查询价格
    queryPriceByStyleId() {
      this.loading=true;
      this.priceModel=[];
      this.stylePriceArr=[];
      this.readFlag=[];
      this.$axios({
        method: "GET",
        url: "/stylePrice/queryByStyleId",
        params: {
          styleId: this.styleInfo.id,
        }
      }).then(response => {
        response = response.data.data;
        let shopIdSet = new Set();
        let stylePriceArr = [];
        response.map((k,index) => {
          this.priceModel.push(k.price)
          k.index=index
          shopIdSet.add(k.shopId);
        });
        shopIdSet.forEach(item=> {
          stylePriceArr.push({shopId: item,shopName:"",data:[]});
        })
        stylePriceArr = Array.from(stylePriceArr).map((k,index)=>{
          k.readFlag=index;
          this.readFlag.push(true);
          return k;
        });
        response.map(k => {
          for(let item of stylePriceArr){
            if (item.shopId === k.shopId) {
              item.shopName = k.shopName;
              item.data.push(k);
            }
          }
        });
        this.stylePriceArr.push(...stylePriceArr);
        this.loading=false;
      })
    },

    //改变是否编辑
    changeReadOnly(index){
      this.$set(this.readFlag,index,!this.readFlag[index])
    },

    //检查订单校验
    addPriceCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return false;
        }
      })
      this.$confirm("是否确定添加此价格?", '添加价格:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "PUT",
          url: "/stylePrice/addStylePrice",
          params: {
            shopId: this.form.shopId,
            styleId: this.styleInfo.id,
            priceType:this.form.priceType,
            price:this.form.price,
            tenantCrop:this.form.tenantCrop
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("添加成功!")
            this.queryPriceByStyleId();
            this.editState=false;
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    //修改价格  params(一条数据,更新后的值,店铺的框的顺序)
    inputChange(child,event,key){
      let value="是否将"+child.shopName+"的"+child.priceType+"从原来的"+child.price+"修改为"+event+"?"
      this.$confirm(value, '修改价格', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method:"POST",
          url:"/stylePrice/updateStylePrice",
          params:{
            id:child.id,
            price:event
          }
        }).then(response=>{
          if (response.data.code === 200) {
            this.queryPriceByStyleId();
            this.$message.success("更改成功");
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        });
        this.$set(this.priceModel,child.index,child.price);
      });
    },
    //同步价格
    syncPrice(item){
      this.$confirm("是否将此店铺价格同步到所有店铺?", '同步价格', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method:"POST",
          url:"/stylePrice/syncPrice",
          params:{
            styleId: this.styleInfo.id,
            shopId:item.shopId,
            tenantCrop:this.form.tenantCrop
          }
        }).then(response=>{
          if (response.data.code === 200) {
            this.$message.success("同步成功");
            this.queryPriceByStyleId();
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消同步'
        });
      });
    },
    close: function () {
      this.$emit("close", false);
    },
  }
}
</script>

<style scoped>
/deep/ .el-col-12 {
  height: 250px;
  /*margin-bottom: 3%;*/
}
</style>