<template>
<div>
  <fieldset style="width:97%">
    <legend>搜索信息</legend>
    <el-row >
      <el-col :span="3">
        <el-select v-model="form.styleType" placeholder="款式类型" clearable class="searchParam" size="medium">
          <el-option
              v-for="item in styleTypeIdArray"
              :key="item.value"
              :label="item.name"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-input v-model="form.styleName" placeholder="款式编号" class="searchParam" size="medium"></el-input>
      </el-col>
      <el-col :span="3">
        <el-input v-model="form.styleAlias" placeholder="款式名称" class="searchParam" size="medium"></el-input>
      </el-col>
      <el-col :span="3">
        <el-select v-model="form.clothesSize" placeholder="婚纱尺寸" clearable class="searchParam" size="medium">
          <el-option
              v-for="item in sizeArray"
              :key="item.value"
              :label="item.name"
              :value="item.name"/>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-input v-model="form.clothesNo" placeholder="婚纱编号" class="searchParam" size="medium"></el-input>
      </el-col>
      <el-col :span="3">
        <el-select v-model="form.styleLabels" filterable multiple placeholder="选择标签" clearable class="searchParam" size="medium">
          <el-option
              v-for="item in labelTypeIdArray"
              :key="item.value"
              :label="item.name"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select v-model="form.brandId" placeholder="选择品牌" clearable class="searchParam" size="medium">
          <el-option
              v-for="item in brandIdArray"
              :key="item.value"
              :label="item.name"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>

    </el-row>
    <el-row>
      <el-col :span="3">
        <el-select v-model="form.isDoubt" placeholder="婚纱存疑" clearable class="searchParam" size="medium">
          <el-option label="所有" value=""/>
          <el-option label="明确" value="0"/>
          <el-option label="存疑" value="1"/>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="search" size="medium">搜索</el-button>
      </el-col>
    </el-row>
    <br>
    <el-row>

<!--      <el-col :span="4">-->
<!--        <el-select v-model="form.clothesShop" placeholder="选择店铺" clearable @change="shopChange" class="searchParam" size="medium">-->
<!--          <el-option-->
<!--              v-for="item in shopIdArray"-->
<!--              :key="item.value"-->
<!--              :label="item.name"-->
<!--              :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-col>-->
<!--      <el-col :span="4">-->
<!--        <el-select v-model="form.clothesPosition" placeholder="选择位置" clearable class="searchParam" size="medium">-->
<!--          <el-option-->
<!--              v-for="item in positionIdArray"-->
<!--              :key="item.value"-->
<!--              :label="item.name"-->
<!--              :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-col>-->

    </el-row>
  </fieldset>
  <br>
  <el-collapse style="margin-left: 10px;width: 98%">
    <el-collapse-item v-for="(value,index) in positionCountList" :key="index">
      <template slot="title">
        <i class="el-icon-s-shop"  style="margin: 0 2%" ></i> <p style="font-size: 15px">{{ value[0].shopName }}</p>
      </template>
      <el-row :gutter="20" >
        <el-col :span="3" >
          <el-row><p class="positionP" style="color: #1AA094">总数</p></el-row>
          <el-row><p class="countP" style="color: #1AA094">{{getPositionCount(value)}}</p></el-row>
        </el-col>
        <el-col :span="3" >
          <el-row><p class="positionP" style="color: #1AA094">存疑数量</p></el-row>
          <el-row><p class="countP" style="color: #1AA094">{{value[0].doubtCount}}</p></el-row>
        </el-col>
        <el-col :span="3" v-for="(chilValue,chilIndex) in value" :key="chilIndex" style="cursor: pointer">
          <el-row  v-if="chilValue.dummy === '1'&&chilValue.count!==0">
            <p :class="chilValue.positionId===changePositionColor?'selectPositionP':'positionP'"
                      @click="clickPosition(chilValue.positionId)">{{chilValue.positionName}}
            </p>
          </el-row>


          <el-row v-if="chilValue.dummy === '0'&&chilValue.count!==0" >

            <p :class="chilValue.positionId===changePositionColor?'selectPositionP':'positionP'"
                                                     style="color: #b8a53d" @click="clickPosition(chilValue.positionId)">{{chilValue.positionName}}
            </p>
          </el-row>

          <el-row v-if="chilValue.count!==0"><p class="countP" @click="clickPosition(chilValue.positionId)">{{chilValue.count}}</p></el-row>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>

  <div class="infinite-list-wrapper">
    <el-row :gutter="15" style="margin: 0 auto;" class="list" v-infinite-scroll="load" :infinite-scroll-disabled="disabled">
      <el-col :span="4" v-for="item in styleList" :key="item.clothesId" style="margin: 1% 0 0 0;" class="list-item">
        <div style="text-align: center">
            <el-badge :value="item.brand===''?'无':item.brand" class="item" type="primary">
              <div style="width: 14vw;height: 19vw">
                <img
                    style="width: 100%; height: 100%;cursor:zoom-in;" @click="openStyleImages(item.styleId)"
                    :src="'https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.styleImage+'?imageMogr2/rquality/60'" alt="加载失败"/>
              </div>
            </el-badge>

          <div style=" width: 14vw; ">
            <el-row :gutter="5" style="margin-top: 2%">
              <el-col :span="7">
                <el-tooltip class="item" effect="dark" :content="item.styleAlias===''?'无':item.styleAlias" placement="top">
                  <p class="clothesName" style="text-align: left;">{{item.styleAlias===''?'无':item.styleAlias}}</p>
                </el-tooltip>
              </el-col>
              <el-col :span="10">
                <el-tooltip class="item" effect="dark" :content="`${item.styleType} - ${item.styleName} - ${item.clothesSize} - ${item.clothesNo}`" placement="top">
                  <p class="clothesName" style="text-align: center;">{{`${item.styleType}-${item.styleName}-${item.clothesSize}-${item.clothesNo}`}}</p>
                </el-tooltip>
              </el-col>
              <el-col :span="7">
                <el-tooltip class="item" effect="dark" :content="item.positionName===''?'无':item.positionName" placement="top">
                  <p class="clothesName" style="text-align: right;">{{item.positionName===''?'无':item.positionName}}</p>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row class="link-button" style="margin-top: 2%;">
              <el-col :span="3" style="text-align: left">
                <el-button class="clothesName" type="text" @click="openClothesDetail(item)" style="color:#656565 ">编辑</el-button>
              </el-col>
              <el-col :span="3" style="text-align: center">
                <el-button class="clothesName" type="text" @click="openClothesSchedule(item)" style="color:#656565 ">档期</el-button>
              </el-col>
              <el-col :span="3" style="text-align: center">
                <el-button class="clothesName" type="text" @click="openStylePrice(item)" style="color:#656565 ">价格</el-button>
              </el-col>
              <el-col :span="3" style="text-align: center">
                <el-button class="clothesName" type="text" @click="delClothesById(item)" style="color:#656565 ">删除</el-button>
              </el-col>
              <el-col :span="5" style="text-align: right">
                <el-button class="clothesName" type="text" @click="openClothesOperation(item)" style="color:#656565 ">位置信息</el-button>
              </el-col>
            </el-row>
          </div>

        </div>
      </el-col>
    </el-row>
    <p class="clothesP"  v-loading="loading" element-loading-text="加载中"></p>
    <p class="clothesP" v-if="noMore">没有更多了...</p>
  </div>
  <clothesDetail v-if="clothesDetailState" :clothesInfo="clothesInfo"  :state="clothesDetailState" @close="clothesDetailState=false"/>
  <clothesScheduleDetails v-if="schedulePageState" :clothesId="clothesInfo.clothesId"  :state="schedulePageState" @close="schedulePageState=false"/>
  <operationAdd v-if="operationState" :state="operationState" :clothes="clothesInfo" @close="operationClose"></operationAdd>
  <stylePrice v-if="stylePricePageState" :styleInfo="styleInfo" :state="stylePricePageState" @close="stylePricePageState=false"/>
</div>
</template>

<script>
import clothesDetail from "@/pages/clothes/clothes-detail";
import clothesScheduleDetails from "@/components/clothes/clothes-schedule-details"
import operationAdd from "@/pages/clothes/operation/operation-add.vue"
import stylePrice from "@/pages/style/style-price";


export default {
  name: "clothes-list",
  data() {
    return {
      form:{
        styleType:"",
        styleName:"",
        styleAlias:"",
        clothesSize:"",
        clothesNo:"",
        styleLabels:"",
        clothesShop:"",
        clothesPosition:"",
        brandId:"",
        isDoubt:"",
        tenantCrop: localStorage.getItem("tenantCrop")
      },

      page:1,
      shopIdArray:[],
      positionIdArray:[],
      styleTypeIdArray:[],
      labelTypeIdArray:[],
      brandIdArray:[],
      styleList:[],
      positionCountList:[],
      changePositionColor:0,

      clothesDetailState:false,
      schedulePageState: false,
      stylePricePageState: false,
      operationState: false,
      clothesInfo:"",
      styleInfo:{},

      total:0,
      loading: false,
      disabled:true,
      noMore:false,
      sizeArray: [],
    }
  },
  components:{clothesDetail,clothesScheduleDetails,operationAdd,stylePrice},
  created() {
    this.queryShopIdArr();
    this.queryStyleTypeIdsArr();
    this.queryPositionClothesCount()
    this.queryLabelIds();
    this.queryBrandIds();
    this.queryClothesSize()
  },
  methods: {
    search(){
      this.styleList=[];
      this.page=1;
      this.total=0;
      this.form.clothesPosition="";
      this.changePositionColor=0;
      this.queryStyleList()
      this.queryPositionClothesCount()
    },
    //查询款式列表
    queryStyleList(){
      this.$axios({
        method: "GET",
        url: "/clothes/clothesList",
        params: {
          page: this.page,
          limit: 30,
          styleType: this.form.styleType,
          styleName: this.form.styleName,
          clothesSize: this.form.clothesSize,
          clothesNo: this.form.clothesNo,
          // clothesShop: this.form.clothesShop,
          clothesPosition: this.form.clothesPosition,
          tenantCrop: this.form.tenantCrop,
          styleLabels: this.form.styleLabels.toString(),
          brandId: this.form.brandId,
          styleAlias: this.form.styleAlias,
          isDoubt: this.form.isDoubt,
        }
      }).then(response => {
        this.styleList.push(...response.data.data.list)
        this.total=response.data.data.total
        this.disabled=false;
        this.loading=false;
      })
    },
    //查询各个位置婚纱总数
    queryPositionClothesCount(){
      this.$axios({
        method: "GET",
        url: "/clothes/queryPositionClothesCount",
        params: {
          page: this.page,
          limit: 30,
          styleType: this.form.styleType,
          styleName: this.form.styleName,
          clothesSize: this.form.clothesSize,
          clothesNo: this.form.clothesNo,
          clothesShop: this.form.clothesShop,
          clothesPosition: this.form.clothesPosition,
          tenantCrop: this.form.tenantCrop,
          styleLabels: this.form.styleLabels.toString(),
          brandId: this.form.brandId,
          styleAlias: this.form.styleAlias,
          isDoubt: this.form.isDoubt,
          indexPosition: "DESC"
        }
      }).then(response => {
        this.positionCountList=[];
        this.positionCountList.push(...response.data.data);
      })
    },
    //获取一个店铺的婚纱总数
    getPositionCount(value){
      let count=0;
      value.forEach(k=>{
        count+=k.count
      })
      return isNaN(count)?0:count;
    },
    //点击位置
    clickPosition(position){
      this.changePositionColor=position
      this.styleList=[];
      this.page=1;
      this.total=0;
      this.form.clothesPosition=position;
      this.queryStyleList();
    },
    //查询店铺
    queryShopIdArr(){
      this.$selectUtils.shopIdsIsValid().then(response=>{
        this.shopIdArray=JSON.parse(response.data.data)
      })
    },
    //店铺更改
    shopChange(val){
      if (val===""){
        this.positionIdArray=[];
        this.form.clothesPosition="";
      }else {
        this.$selectUtils.queryPositionIdsByShopId(val).then(response=>{
          this.positionIdArray=JSON.parse(response.data.data)
        })
      }
    },
    //查询款式类型
    queryStyleTypeIdsArr(){
      this.$selectUtils.queryStyleTypeIds().then(response=>{
        this.styleTypeIdArray=JSON.parse(response.data.data);
      })
    },
    //查询标签
    queryLabelIds(){
      this.$selectUtils.queryLabelIds(this.$labelType.style).then(response=>{
        this.labelTypeIdArray=response.data.data;
      })
    },
    //查询品牌
    queryBrandIds(){
      this.$selectUtils.queryBrandIds().then(response=>{
        this.brandIdArray=JSON.parse(response.data.data);
      })
    },
    openStyleImages(styleId){
      this.$axios({
        method: "GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: styleId
        }
      }).then(response => {
        this.$viewerApi({
          images: response.data.data,
        })
      })
    },
    //打开婚纱详情
    openClothesDetail(clothesInfo){
      this.clothesInfo=clothesInfo;
      this.clothesDetailState=true;
    },
    //打开档期
    openClothesSchedule(clothesInfo){
      this.clothesInfo=clothesInfo;
      this.schedulePageState=true;
    },
    openStylePrice(clothesInfo){
      this.styleInfo={id:clothesInfo.styleId,typeName:clothesInfo.styleType,styleName:clothesInfo.styleName}
      this.stylePricePageState=true;
    },
    //打开位置操作
    openClothesOperation(clothesInfo){
      this.$axios({
        method:"GET",
        url: "/clothes/queryClothesVo",
        params: {
          id: this.clothesInfo.clothesId
        }
      }).then(response=>{
        this.details=response.data.data
        this.clothesInfo=clothesInfo;
        this.operationState=true;
      })
    },
    //删除婚纱
    delClothesById(clothesInfo){
      let value=`是否删除该婚纱 : <span style="color: red">${clothesInfo.styleType}-${clothesInfo.styleName}-${clothesInfo.clothesSize}-${clothesInfo.clothesNo}</span> ?`;
      this.$confirm(value, '删除婚纱', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method:"POST",
          url:"/clothes/deleteById",
          params:{
            clothesId:clothesInfo.clothesId
          }
        }).then(response=>{
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self=this;
            setTimeout(function (){self.search()},1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //家宅
    load() {
      this.disabled=true;
      if (this.styleList.length>=this.total){
        this.noMore=true;
        this.loading=false;
        return;
      }
      this.loading = true;
      this.noMore=false;
      this.page++;
      setTimeout(() => {
        this.queryStyleList();
      }, 1000)
    },
    queryClothesSize() {
      this.$selectUtils.queryClothesSizeIds().then(response => {
        this.sizeArray = JSON.parse(response.data.data)
      })
    },
    //关闭位置操作
    operationClose(){
      this.operationState = false
      this.search();
    },
  }
}
</script>

<style scoped>
.searchParam{
  width: 10vw;
}
.clothesName {
  font-size: 0.7vw;
  /*color:#1E9FFF;*/
  text-decoration:none;
  font-weight: bold;

  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.clothesP{
  margin: 5% 0;
  font-size: 23px;
  text-align: center;
}
.positionP{
  height: 70px;
  margin: 10% 0 0 0;
  font-size: 20px;
  text-align: center;
  color: black;
}
.selectPositionP{
  height: 68px;
  margin: 10% 0 0 0;
  font-size: 20px;
  text-align: center;
  color:  #1A73E8;
  border-bottom: 2px solid #1A73E8
}
.countP{
  font-size: 25px;
  text-align: center;
  color: black;
}
.header-icon el-icon-info{
  line-height: 0;
}

.pTitle{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin: 0 0;
  font-size: 0.6vw;
  font-weight: bold;
}
.el-collapse-item >>> .el-collapse-item__header{
  background-color: #f2f2f2;
  border: 1px solid #e0dfdf;
}

/*图片品牌角标*/
.item >>>.el-badge__content.is-fixed{
  transform:none;
  left: 0;
  right: unset ;
}
.link-button>>>.el-button{
  padding: unset;
}
</style>